import { call, put, select } from 'redux-saga/effects';
import actions from '../../../actions';

export function* createSettings() {
  yield put(actions.createSettings({
    showDetails: false,
  }));
}

function* updateSettings(settings) {
  console.log('updateSettingsSettings', settings)
  yield put(actions.updateSettings(settings));
}


export default {
  createSettings,
  updateSettings,
}
