import { attr } from 'redux-orm';

import BaseModel from './BaseModel';

export default class extends BaseModel {
  static modelName = 'Settings';

  static fields = {
    id: attr(),
    showDetails: attr(),
  };

  static reducer({ type, payload }, Settings) {
    switch (type) {
      case 'CREATE_SETTINGS':
      case 'SETTINGS_UPDATE':
        Settings.upsert(payload);
        break;
      case 'ACTIVITIES_DETAILS_TOGGLE':
        Settings.first().update({ showDetails: payload.isVisible });
        break;
      default:
        break;
    }
  }
}

