const createSettings = (settings) => {
  return {
    type: 'CREATE_SETTINGS',
    payload:{
    settings
      }
  }
}

const updateSettings = (settings) => {
  return {
    type: 'UPDATE_SETTINGS',
    payload: {
      settings
    }
  }
}

export default {
  createSettings,
  updateSettings
}
