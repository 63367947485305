import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Grid, Icon, Modal } from 'semantic-ui-react';
import { usePopup } from '../../lib/popup';
import { Markdown } from '../../lib/custom-ui';

import { startStopwatch, stopStopwatch } from '../../utils/stopwatch';
import NameField from './NameField';
import DescriptionEdit from './DescriptionEdit';
import Tasks from './Tasks';
import Attachments from './Attachments';
import AttachmentAddZone from './AttachmentAddZone';
import AttachmentAddStep from './AttachmentAddStep';
import Activities from './Activities';
import User from '../User';
import Label from '../Label';
import DueDate from '../DueDate';
import Stopwatch from '../Stopwatch';
import BoardMembershipsStep from '../BoardMembershipsStep';
import LabelsStep from '../LabelsStep';
import DueDateEditStep from '../DueDateEditStep';
import StopwatchEditStep from '../StopwatchEditStep';
import CardMoveStep from '../CardMoveStep';
import DeleteStep from '../DeleteStep';

import styles from './CardModal.module.scss';
import StoryPoints from '../StoryPoints';
import StoryPointsEditSteps from '../StoryPointsEditStep/StoryPointsEditStep';

const CardModal = React.memo(
  ({
    id,
    name,
    description,
    slateDescription,
    dueDate,
    allUsers,
    isDueDateCompleted,
    stopwatch,
    storyPoints,
    archived,
    isSubscribed,
    isActivitiesFetching,
    isAllActivitiesFetched,
    isActivitiesDetailsVisible,
    isActivitiesDetailsFetching,
    listId,
    boardId,
    projectId,
    users,
    currentUserId,
    labels,
    tasks,
    creatorUser,
    createSettings,
    updateSettings,
    selectSettings,
    taskGroups,
    onTaskGroupUpdate,
    onTaskGroupCreate,
    onTaskGroupDelete,
    attachments,
    activities,
    allProjectsToLists,
    allBoardMemberships,
    allLabels,
    canEdit,
    canEditCommentActivities,
    canEditAllCommentActivities,
    onUpdate,
    onMove,
    onTransfer,
    onDuplicate,
    onDelete,
    onUserAdd,
    onUserRemove,
    onBoardFetch,
    onLabelAdd,
    onLabelRemove,
    onLabelCreate,
    onLabelUpdate,
    onLabelMove,
    onLabelDelete,
    onTaskCreate,
    onTaskUpdate,
    onTaskMove,
    onTaskDelete,
    onUserTaskCreate,
    onUserTaskDelete,
    onAttachmentCreate,
    onAttachmentUpdate,
    onAttachmentDelete,
    onActivitiesFetch,
    onActivitiesDetailsToggle,
    onCommentActivityCreate,
    onCommentActivityUpdate,
    onCommentActivityDelete,
    onClose,
  }) => {
    const [t] = useTranslation();
    const [isLinkCopied, setIsLinkCopied] = useState(false);

    // on first load check from selectSettings that showDetails is true or false if it is trigger a onActivitiesDetailsToggle
    useEffect(() => {
      if (!selectSettings) {
        createSettings()
      }
      if (selectSettings && selectSettings.showDetails === true) {
        onActivitiesDetailsToggle(true)
      }
    }, [createSettings, onActivitiesDetailsToggle, selectSettings])
    const isGalleryOpened = useRef(false);

    const handleToggleStopwatchClick = useCallback(() => {
      onUpdate({
        stopwatch: stopwatch.startedAt ? stopStopwatch(stopwatch) : startStopwatch(stopwatch),
      });
    }, [stopwatch, onUpdate]);

    const handleNameUpdate = useCallback(
      (newName) => {
        onUpdate({
          name: newName,
        });
      },
      [onUpdate],
    );

    const handleGroupNameUpdate = useCallback(
      (groupId, newName, cardId) => {
        onTaskGroupUpdate({
          id: groupId,
          name: newName,
          cardId
        })
      },
      [onTaskGroupUpdate],
    )

    const handleArchiveUpdate = useCallback(
      () => {
        onUpdate({
          archived: !archived,
        })
      }, [onUpdate, archived]
    )

    const handleDescriptionUpdate = useCallback(
      (newDescription) => {
        onUpdate({
          description: newDescription.markdown,
          slateDescription: newDescription.slate,
        });
      },
      [onUpdate],
    );

    const handleDueDateUpdate = useCallback(
      (newDueDate) => {
        onUpdate({
          dueDate: newDueDate,
        });
      },
      [onUpdate],
    );

    const handleDueDateCompletionChange = useCallback(() => {
      onUpdate({
        isDueDateCompleted: !isDueDateCompleted,
      });
    }, [isDueDateCompleted, onUpdate]);

    const handleStopwatchUpdate = useCallback(
      (newStopwatch) => {
        onUpdate({
          stopwatch: newStopwatch,
        });
      },
      [onUpdate],
    );

    const handleStoryPointsUpdate = useCallback(
      (newStoryPoints) => {
        onUpdate({
          storyPoints: Number(newStoryPoints),
        });
      },
      [onUpdate],
    );

    const handleCoverUpdate = useCallback(
      (newCoverAttachmentId) => {
        onUpdate({
          coverAttachmentId: newCoverAttachmentId,
        });
      },
      [onUpdate],
    );

    const handleToggleSubscriptionClick = useCallback(() => {
      onUpdate({
        isSubscribed: !isSubscribed,
      });
    }, [isSubscribed, onUpdate]);

    const handleDuplicateClick = useCallback(() => {
      onDuplicate();
      onClose();
    }, [onDuplicate, onClose]);

    const handleCopyLinkClick = useCallback(() => {
      navigator.clipboard.writeText(window.location.href);
      setIsLinkCopied(true);
      setTimeout(() => {
        setIsLinkCopied(false);
      }, 5000);
    }, []);

    const handleGalleryOpen = useCallback(() => {
      isGalleryOpened.current = true;
    }, []);

    const handleGalleryClose = useCallback(() => {
      isGalleryOpened.current = false;
    }, []);

    const handleClose = useCallback(() => {
      if (isGalleryOpened.current) {
        return;
      }

      onClose();
    }, [onClose]);

    const handleOnUserTaskCreate = useCallback((data) => {
      onUserTaskCreate({ ...data, cardId: id })
    }, [onUserTaskCreate, id])

    const handleOnUserTaskDelete = useCallback((data) => {
      onUserTaskDelete({ ...data, cardId: id })
    }, [onUserTaskDelete, id])

    const handleOnTaskGroupDelete = useCallback((data) => {
      onTaskGroupDelete({ ...data, cardId: id })
    }, [onTaskGroupDelete, id])

    const handleOnTaskGroupCreate = useCallback(() => {
      const groupsLength = taskGroups.length
      onTaskGroupCreate({ totalGroups: groupsLength, cardId: id })
    }, [onTaskGroupCreate, id, taskGroups])

    const handleOnJoinClick = useCallback(() => {
      onUserAdd(currentUserId, id)
    }, [onUserAdd, currentUserId, id])


    const AttachmentAddPopup = usePopup(AttachmentAddStep);
    const BoardMembershipsPopup = usePopup(BoardMembershipsStep);
    const LabelsPopup = usePopup(LabelsStep);
    const DueDateEditPopup = usePopup(DueDateEditStep);
    const StopwatchEditPopup = usePopup(StopwatchEditStep);
    const StoryPointsEditPopup = usePopup(StoryPointsEditSteps)
    const CardMovePopup = usePopup(CardMoveStep);
    const DeletePopup = usePopup(DeleteStep);

    const userIds = users.map((user) => user.id);
    const labelIds = labels.map((label) => label.id);

    const mentionUsers = allUsers

    //   allUsers.map((user) => {
    //   console.log(user)
    //   return {
    //     id: user.id,
    //     display: user.name ?? user.username,
    //     username: user.username ?? user.name,
    //     profileUrl: `/users/${user.id}`,
    //   }
    // })

    const handleCopyPasteBind = useCallback((event) => {
      if (event.ctrlKey && event.key === 'c') {
        const specificElement = document.getElementById('uniquecardmodal');
        if (document.activeElement === specificElement || document.activeElement === document.body) {
          handleCopyLinkClick()
        }
      }
    }, [handleCopyLinkClick])


    useEffect(() => {
      document.addEventListener('keydown', handleCopyPasteBind);

      return () => {
        document.removeEventListener('keydown', handleCopyPasteBind);
      };
    }, [handleCopyPasteBind]
    )

    const contentNode = (
      <Grid className={styles.grid}>
        <Grid.Row className={styles.headerPadding}>
          {archived && <Grid.Column width={16} className={styles.archivedCard}>
            <div className={styles.headerWrapper}>
              <Icon name="archive" className={styles.moduleIcon} />
              <div className={styles.headerTitleWrapper}>
                <div className={styles.headerTitle}>This card is archived</div>
              </div>
            </div>
          </Grid.Column>
          }
          <Grid.Column width={16} className={styles.headerPadding}>
            <div className={styles.headerWrapper}>
              <Icon name="list alternate outline" className={styles.moduleIcon} />
              <div className={styles.headerTitleWrapper}>
                {canEdit ? (
                  <NameField defaultValue={name} onUpdate={handleNameUpdate} />
                ) : (
                  <div className={styles.headerTitle}>{name}</div>
                )}
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>

        {/* NOTE: Membership card display */}
        <Grid.Row className={styles.modalPadding}>
          <Grid.Column width={canEdit ? 12 : 16} className={styles.contentPadding}>
            {(users.length > 0 || labels.length > 0 || dueDate || stopwatch || creatorUser) && (
              <div className={styles.moduleWrapper}>
                {creatorUser && (
                  <div
                    className={styles.attachments}
                  >
                    <div className={styles.text}>
                      <span>Creator</span>
                    </div>
                    <span
                      className={styles.attachment}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <User
                        name={creatorUser.name}
                        avatarUrl={creatorUser.avatarUrl}
                        size="medium"
                        style={{ flexShrink: 0 }}
                      />
                      <span style={{ fontSize: '0.9rem', flexShrink: 0, marginLeft: '8px' }}>
                        {creatorUser.name}
                      </span>
                    </span>
                  </div>
                )}
                {users.length > 0 && (
                  <div className={styles.attachments}>
                    <div className={styles.text}>
                      {t('common.members', {
                        context: 'title',
                      })}
                    </div>
                    {users.map((user) => (
                      <span key={user.id} className={styles.attachment}>
                        {canEdit ? (
                          <BoardMembershipsPopup
                            items={allBoardMemberships}
                            currentUserIds={userIds}
                            onUserSelect={onUserAdd}
                            onUserDeselect={onUserRemove}
                          >
                            <User name={user.name} avatarUrl={user.avatarUrl} />
                          </BoardMembershipsPopup>
                        ) : (
                          <User name={user.name} avatarUrl={user.avatarUrl} />
                        )}
                      </span>
                    ))}
                    {canEdit && (
                      <BoardMembershipsPopup
                        items={allBoardMemberships}
                        currentUserIds={userIds}
                        onUserSelect={onUserAdd}
                        onUserDeselect={onUserRemove}
                      >
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button
                          type="button"
                          className={classNames(styles.attachment, styles.dueDate)}
                        >
                          <Icon name="add" size="small" className={styles.addAttachment} />
                        </button>
                      </BoardMembershipsPopup>
                    )}
                  </div>
                )}
                {labels.length > 0 && (
                  <div className={styles.attachments}>
                    <div className={styles.text}>
                      {t('common.labels', {
                        context: 'title',
                      })}
                    </div>
                    {labels.map((label) => (
                      <span key={label.id} className={styles.attachment}>
                        {canEdit ? (
                          <LabelsPopup
                            key={label.id}
                            items={allLabels}
                            currentIds={labelIds}
                            onSelect={onLabelAdd}
                            onDeselect={onLabelRemove}
                            onCreate={onLabelCreate}
                            onUpdate={onLabelUpdate}
                            onMove={onLabelMove}
                            onDelete={onLabelDelete}
                          >
                            <Label name={label.name} color={label.color} />
                          </LabelsPopup>
                        ) : (
                          <Label name={label.name} color={label.color} />
                        )}
                      </span>
                    ))}
                    {canEdit && (
                      <LabelsPopup
                        items={allLabels}
                        currentIds={labelIds}
                        onSelect={onLabelAdd}
                        onDeselect={onLabelRemove}
                        onCreate={onLabelCreate}
                        onUpdate={onLabelUpdate}
                        onMove={onLabelMove}
                        onDelete={onLabelDelete}
                      >
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button
                          type="button"
                          className={classNames(styles.attachment, styles.dueDate)}
                        >
                          <Icon name="add" size="small" className={styles.addAttachment} />
                        </button>
                      </LabelsPopup>
                    )}
                  </div>
                )}
                {dueDate && (
                  <div className={styles.attachments}>
                    <div className={styles.text}>
                      {t('common.dueDate', {
                        context: 'title',
                      })}
                    </div>
                    <span className={classNames(styles.attachment, styles.attachmentDueDate)}>
                      {canEdit ? (
                        <>
                          <Checkbox
                            checked={isDueDateCompleted}
                            disabled={!canEdit}
                            onChange={handleDueDateCompletionChange}
                          />
                          <DueDateEditPopup defaultValue={dueDate} onUpdate={handleDueDateUpdate}>
                            <DueDate
                              withStatusIcon
                              value={dueDate}
                              isCompleted={isDueDateCompleted}
                            />
                          </DueDateEditPopup>
                        </>
                      ) : (
                        <DueDate withStatusIcon value={dueDate} isCompleted={isDueDateCompleted} />
                      )}
                    </span>
                  </div>
                )}
                {stopwatch && (
                  <div className={styles.attachments}>
                    <div className={styles.text}>
                      {t('common.stopwatch', {
                        context: 'title',
                      })}
                    </div>
                    <span className={styles.attachment}>
                      {canEdit ? (
                        <StopwatchEditPopup
                          defaultValue={stopwatch}
                          onUpdate={handleStopwatchUpdate}
                        >
                          <Stopwatch startedAt={stopwatch.startedAt} total={stopwatch.total} />
                        </StopwatchEditPopup>
                      ) : (
                        <Stopwatch startedAt={stopwatch.startedAt} total={stopwatch.total} />
                      )}
                    </span>
                    {canEdit && (
                      // eslint-disable-next-line jsx-a11y/control-has-associated-label
                      <button
                        type="button"
                        className={classNames(styles.attachment, styles.dueDate)}
                        onClick={handleToggleStopwatchClick}
                      >
                        <Icon
                          name={stopwatch.startedAt ? 'pause' : 'play'}
                          size="small"
                          className={styles.addAttachment}
                        />
                      </button>
                    )}
                  </div>
                )}
                {storyPoints ? (
                  <div className={styles.attachments}>
                    <div className={styles.text}>
                      <span>Story Points</span>
                    </div>
                    <span className={styles.attachment}>
                      {canEdit ? (
                        <StoryPointsEditPopup
                          defaultValue={storyPoints}
                          onUpdate={handleStoryPointsUpdate}
                        >
                          <StoryPoints
                            as="span"
                            value={storyPoints}
                            size="medium"
                          />
                        </StoryPointsEditPopup>
                      ) : (
                        <StoryPoints
                          as="span"
                          value={storyPoints}
                          size="medium"
                        />
                      )}
                    </span>
                  </div>
                ) : null}
              </div>
            )}
            {(description || canEdit) && (
              <div className={styles.contentModule}>
                <div className={styles.moduleWrapper}>
                  <Icon name="align justify" className={styles.moduleIcon} />
                  <div className={styles.moduleHeader}>{t('common.description')}</div>
                  {canEdit ? (
                    <DescriptionEdit defaultValue={slateDescription ?? description} onUpdate={handleDescriptionUpdate} mentionUsers={mentionUsers}>
                      {description ? (
                        <button
                          type="button"
                          className={classNames(styles.descriptionText, styles.cursorPointer)}
                        >
                          <Markdown linkStopPropagation linkTarget="_blank">
                            {description}
                          </Markdown>
                        </button>
                      ) : (
                        <button type="button" className={styles.descriptionButton}>
                          <span className={styles.descriptionButtonText}>
                            {t('action.addMoreDetailedDescription')}
                          </span>
                        </button>
                      )}
                    </DescriptionEdit>
                  ) : (
                    <div className={styles.descriptionText}>
                      <Markdown linkStopPropagation linkTarget="_blank">
                        {description}
                      </Markdown>
                    </div>
                  )}
                </div>
              </div>
            )}
            {(taskGroups && taskGroups.length > 0) && (
              taskGroups.map((taskGroup) => (
                <div className={styles.contentModule} key={taskGroup.id}>
                  <div className={styles.moduleWrapper}>
                    <div className={styles.groupHeader}>
                      <Icon name="check square outline" className={styles.moduleIcon} />
                      <NameField defaultValue={taskGroup.name} onUpdate={(n) => handleGroupNameUpdate(taskGroup.id, n, id)} />
                      <Button className={styles.deleteButton} onClick={() => handleOnTaskGroupDelete(taskGroup)}>Delete</Button>
                    </div>
                    <Tasks
                      items={taskGroup.tasks}
                      canEdit={canEdit}
                      onCreate={(task) => onTaskCreate({ groupId: taskGroup.id, ...task })}
                      onUpdate={onTaskUpdate}
                      onMove={(newTaskId, index) => onTaskMove({ groupId: taskGroup.id, taskId: newTaskId, index})}
                      onDelete={onTaskDelete}
                      users={users}
                      allBoardMemberships={allBoardMemberships}
                      onUserAddTask={handleOnUserTaskCreate}
                      onUserRemoveTask={handleOnUserTaskDelete}
                    />
                  </div>
                </div>

              ))
            )}

            {attachments.length > 0 && (
              <div className={styles.contentModule}>
                <div className={styles.moduleWrapper}>
                  <Icon name="attach" className={styles.moduleIcon} />
                  <div className={styles.moduleHeader}>{t('common.attachments')}</div>
                  <Attachments
                    items={attachments}
                    canEdit={canEdit}
                    onUpdate={onAttachmentUpdate}
                    onDelete={onAttachmentDelete}
                    onCoverUpdate={handleCoverUpdate}
                    onGalleryOpen={handleGalleryOpen}
                    onGalleryClose={handleGalleryClose}
                  />
                </div>
              </div>
            )}
            <Activities
              items={activities}
              isFetching={isActivitiesFetching}
              isAllFetched={isAllActivitiesFetched}
              isDetailsVisible={isActivitiesDetailsVisible}
              isDetailsFetching={isActivitiesDetailsFetching}
              canEdit={canEditCommentActivities}
              canEditAllComments={canEditAllCommentActivities}
              mentionUsers={mentionUsers}
              onFetch={onActivitiesFetch}
              onDetailsToggle={onActivitiesDetailsToggle}
              onCommentCreate={onCommentActivityCreate}
              onCommentUpdate={onCommentActivityUpdate}
              onCommentDelete={onCommentActivityDelete}
              selectorSettings={selectSettings}
            />
          </Grid.Column>
          {canEdit && (
            <Grid.Column width={4} className={styles.sidebarPadding}>
              <div className={styles.actions}>
                <span className={styles.actionsTitle}>{t('action.addToCard')}</span>
                {
                  !userIds.includes(currentUserId) && (
                    <Button fluid className={styles.actionButton} onClick={handleOnJoinClick}>
                      <Icon name="list" className={styles.actionIcon} />
                      Join
                    </Button>
                  )
                }
                <BoardMembershipsPopup
                  items={allBoardMemberships}
                  currentUserIds={userIds}
                  onUserSelect={onUserAdd}
                  onUserDeselect={onUserRemove}
                >
                  <Button fluid className={styles.actionButton}>
                    <Icon name="user outline" className={styles.actionIcon} />
                    {t('common.members')}
                  </Button>
                </BoardMembershipsPopup>

                <LabelsPopup
                  items={allLabels}
                  currentIds={labelIds}
                  onSelect={onLabelAdd}
                  onDeselect={onLabelRemove}
                  onCreate={onLabelCreate}
                  onUpdate={onLabelUpdate}
                  onMove={onLabelMove}
                  onDelete={onLabelDelete}
                >
                  <Button fluid className={styles.actionButton}>
                    <Icon name="bookmark outline" className={styles.actionIcon} />
                    {t('common.labels')}
                  </Button>
                </LabelsPopup>
                <Button fluid className={styles.actionButton} onClick={handleOnTaskGroupCreate}>
                  <Icon name="list" className={styles.actionIcon} />
                  Tasks
                </Button>
                <DueDateEditPopup defaultValue={dueDate} onUpdate={handleDueDateUpdate}>
                  <Button fluid className={styles.actionButton}>
                    <Icon name="calendar check outline" className={styles.actionIcon} />
                    {t('common.dueDate', {
                      context: 'title',
                    })}
                  </Button>
                </DueDateEditPopup>
                <StopwatchEditPopup defaultValue={stopwatch} onUpdate={handleStopwatchUpdate}>
                  <Button fluid className={styles.actionButton}>
                    <Icon name="clock outline" className={styles.actionIcon} />
                    {t('common.stopwatch')}
                  </Button>
                </StopwatchEditPopup>
                <StoryPointsEditPopup defaultValue={storyPoints} onUpdate={handleStoryPointsUpdate}>
                  <Button fluid className={styles.actionButton}>
                    <Icon name="book" className={styles.actionIcon} />
                    <span>Story Points</span>
                  </Button>
                </StoryPointsEditPopup>
                <AttachmentAddPopup onCreate={onAttachmentCreate}>
                  <Button fluid className={styles.actionButton}>
                    <Icon name="attach" className={styles.actionIcon} />
                    {t('common.attachment')}
                  </Button>
                </AttachmentAddPopup>
              </div>
              <div className={styles.actions}>
                <span className={styles.actionsTitle}>{t('common.actions')}</span>
                <Button
                  fluid
                  className={styles.actionButton}
                  onClick={handleToggleSubscriptionClick}
                >
                  <Icon name="paper plane outline" className={styles.actionIcon} />
                  {isSubscribed ? t('action.unsubscribe') : t('action.subscribe')}
                </Button>
                <CardMovePopup
                  projectsToLists={allProjectsToLists}
                  defaultPath={{
                    projectId,
                    boardId,
                    listId,
                  }}
                  onMove={onMove}
                  onTransfer={onTransfer}
                  onBoardFetch={onBoardFetch}
                >
                  <Button
                    fluid
                    className={styles.actionButton}
                    onClick={handleToggleSubscriptionClick}
                  >
                    <Icon name="share square outline" className={styles.actionIcon} />
                    {t('action.move')}
                  </Button>
                </CardMovePopup>
                <Button fluid className={styles.actionButton} onClick={handleDuplicateClick}>
                  <Icon name="copy outline" className={styles.actionIcon} />
                  {t('action.duplicate')}
                </Button>
                <Button fluid className={styles.actionButton} onClick={handleArchiveUpdate}>
                  {archived ? (
                    <span>
                      <Icon name="redo" className={styles.actionIcon} />
                      Send to board
                    </span>
                  ) : (
                    <span>
                      <Icon name="archive" className={styles.actionIcon} />
                      Archive
                    </span>
                  )}
                </Button>
                {
                  archived && (
                    <DeletePopup
                      title="common.deleteCard"
                      content="common.areYouSureYouWantToDeleteThisCard"
                      buttonContent="action.deleteCard"
                      onConfirm={onDelete}
                    >
                      <Button fluid className={styles.actionButton} style={
                        {
                          backgroundColor: "red",
                          color: "white"
                        }
                      }>
                        <Icon name="trash alternate outline" className={styles.actionIcon} />
                        {t('action.delete')}
                      </Button>
                    </DeletePopup>
                  )
                }
                {window.isSecureContext && (
                  <Button fluid className={styles.actionButton} onClick={handleCopyLinkClick}>
                    <Icon
                      name={isLinkCopied ? 'linkify' : 'unlink'}
                      className={styles.actionIcon}
                    />
                    {isLinkCopied
                      ? t('common.linkIsCopied')
                      : t('action.copyLink', {
                        context: 'title',
                      })}
                  </Button>
                )}
                <DeletePopup
                  title="common.deleteCard"
                  content="common.areYouSureYouWantToDeleteThisCard"
                  buttonContent="action.deleteCard"
                  onConfirm={onDelete}
                >
                  <Button fluid className={styles.actionButton}>
                    <Icon name="trash alternate outline" className={styles.actionIcon} />
                    {t('action.delete')}
                  </Button>
                </DeletePopup>
              </div >
            </Grid.Column >
          )}
        </Grid.Row >
      </Grid >
    );

    return (
      <Modal open closeIcon centered={false} onClose={handleClose} className={styles.wrapper} id="uniquecardmodal">
        {canEdit ? (
          <AttachmentAddZone onCreate={onAttachmentCreate}>{contentNode}</AttachmentAddZone>
        ) : (
          contentNode
        )}
      </Modal>
    );
  },
);

CardModal.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  slateDescription: PropTypes.string,
  archived: PropTypes.bool,
  dueDate: PropTypes.instanceOf(Date),
  isDueDateCompleted: PropTypes.bool,
  stopwatch: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  selectSettings: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  storyPoints: PropTypes.number,
  isSubscribed: PropTypes.bool.isRequired,
  isActivitiesFetching: PropTypes.bool.isRequired,
  isAllActivitiesFetched: PropTypes.bool.isRequired,
  isActivitiesDetailsVisible: PropTypes.bool.isRequired,
  isActivitiesDetailsFetching: PropTypes.bool.isRequired,
  listId: PropTypes.string.isRequired,
  boardId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  users: PropTypes.array.isRequired,
  allUsers: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  taskGroups: PropTypes.array.isRequired,
  attachments: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  allProjectsToLists: PropTypes.array.isRequired,
  allBoardMemberships: PropTypes.array.isRequired,
  allLabels: PropTypes.array.isRequired,
  creatorUser: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
  canEdit: PropTypes.bool.isRequired,
  canEditCommentActivities: PropTypes.bool.isRequired,
  canEditAllCommentActivities: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onTransfer: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUserAdd: PropTypes.func.isRequired,
  onUserRemove: PropTypes.func.isRequired,
  onBoardFetch: PropTypes.func.isRequired,
  onLabelAdd: PropTypes.func.isRequired,
  onLabelRemove: PropTypes.func.isRequired,
  onLabelCreate: PropTypes.func.isRequired,
  onLabelUpdate: PropTypes.func.isRequired,
  onLabelMove: PropTypes.func.isRequired,
  onLabelDelete: PropTypes.func.isRequired,
  onTaskCreate: PropTypes.func.isRequired,
  onTaskUpdate: PropTypes.func.isRequired,
  onTaskMove: PropTypes.func.isRequired,
  onTaskDelete: PropTypes.func.isRequired,
  onUserTaskCreate: PropTypes.func.isRequired,
  onUserTaskDelete: PropTypes.func.isRequired,
  onAttachmentCreate: PropTypes.func.isRequired,
  onAttachmentUpdate: PropTypes.func.isRequired,
  onAttachmentDelete: PropTypes.func.isRequired,
  onActivitiesFetch: PropTypes.func.isRequired,
  onActivitiesDetailsToggle: PropTypes.func.isRequired,
  onCommentActivityCreate: PropTypes.func.isRequired,
  onCommentActivityUpdate: PropTypes.func.isRequired,
  onCommentActivityDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onTaskGroupUpdate: PropTypes.func.isRequired,
  onTaskGroupCreate: PropTypes.func.isRequired,
  onTaskGroupDelete: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
  createSettings: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
};

CardModal.defaultProps = {
  description: undefined,
  slateDescription: undefined,
  dueDate: undefined,
  isDueDateCompleted: false,
  stopwatch: undefined,
  storyPoints: undefined,
  archived: false,
  selectSettings: undefined,
};

export default CardModal;
